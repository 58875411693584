import { Layout } from "app/components/layout";
import { Header1 } from "app/components/header";
import { Feature1 } from "app/layouts/feature1";
import { Feature2 } from "app/layouts/feature2";
import { Hero1 } from "app/layouts/hero1";
import { CTA1 } from "app/layouts/cta1";
import { FAQ1 } from "app/layouts/faq1";
import { FeaturesList } from "app/layouts/featureslist1";
import { CTA2 } from "app/layouts/cta2";
import { Testimonial1 } from "app/layouts/testimonial1";
import { Footer1 } from "app/layouts/footer1";
import * as React from "react";
import export2 from "../assets/images/export2.png";
import code2 from "../assets/images/code2.png";
import metrics from "../assets/images/metrics.png";

const IndexPage: React.FC = () => (
  <Layout>
    <div className="bg-white pb-8 sm:pb-12 lg:pb-12 font-sans">
      <Header1 />

      <Hero1
        link="Documentation"
        title="Allure TestOps"
        text="Next-gen Software Quality Management Platform spojuje automatizované a ruční testování. Zvyšte kontrolu kvality svého produktu a zvyšte produktivitu svého týmu QA a vývojového týmu nastavením TestOps."
        image="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQMAAADCCAMAAAB6zFdcAAAAA1BMVEWw3+W05AztAAAASElEQVR4nO3BMQEAAADCoPVPbQwfoAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIC3AcUIAAFkqh/QAAAAAElFTkSuQmCC"
      />

      <Feature2
        title="Centrální rozbočovač pro všechny vaše testy"
        subtitle="Allure TestOps propojuje manuální i automatizované testování, což zvyšuje produktivitu vašeho týmu. Posilte a zrychlete svůj kanál CI/CD a získejte okamžitý přehled o pokrytí testů."
        image={export2}
      />

      <Feature1
        title="Živá dokumentace"
        subtitle="Umožněte Allure TestOps aktualizovat vaši testovací dokumentaci na základě sledování a analýzy výsledků testů a už nebudete mít žádné omluvy pro zastaralé testovací případy. Bez námahy."
        image={code2}
      />

      <Feature2
        title="Metriky a KPI"
        subtitle="Zkontrolujte stav svého produktu, vytvářejte cíle a sledujte postup pomocí Allure TestOps Analytics. Vytvořte si vlastní sadu klíčových ukazatelů výkonu pomocí jazyka Allure Query Language a sledujte průběh svého projektu na první pohled."
        image={metrics}
      />

      <CTA2
        title_line1="Jste připraveni se ponořit?"
        title_line2="Začněte náš nový produkt ještě dnes!"
        text="Ac euismod vel sit maecenas id pellentesque eu sed consectetur. Malesuada adipiscing sagittis vel nulla nec."
        button_label="Spustit zkušební verzi"
      />

      {/*<CTA1*/}
      {/*  title_line1="Ready to dive in?"*/}
      {/*  title_line2="Start our new product today!"*/}
      {/*  text="Ac euismod vel sit maecenas id pellentesque eu sed consectetur. Malesuada adipiscing sagittis vel nulla nec."*/}
      {/*  button_label="Start trial"*/}
      {/*/>*/}

      {/*<Feature2*/}
      {/*  title="Graphs Header"*/}
      {/*  subtitle="During report generation (second step), the XML files are transformed to a HTML report. This can be done with a command line tool, a plugin for CI or a build tool. See examples and documentation for more details."*/}
      {/*  image="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQMAAADCCAMAAAB6zFdcAAAAA1BMVEWw3+W05AztAAAASElEQVR4nO3BMQEAAADCoPVPbQwfoAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIC3AcUIAAFkqh/QAAAAAElFTkSuQmCC"*/}
      {/*/>*/}

      {/*<Feature1*/}
      {/*  title="xUnit Header"*/}
      {/*  subtitle="Allure is based on standard xUnit results output but adds some supplementary data. Any*/}
      {/*        report is generated in two steps. During test execution (first step), a small library*/}
      {/*        called adapter attached to the testing framework saves information about executed*/}
      {/*        tests to XML files. We already provide adapters for popular Java, PHP, Ruby, Python,*/}
      {/*        Scala and C# test frameworks."*/}
      {/*  quite={{*/}
      {/*    text:*/}
      {/*      "Allure Report is the product ever made by humanity, I can't imagine my life without it anymore.",*/}
      {/*    user: {*/}
      {/*      name: "Dmitry Baev",*/}
      {/*      position: "CO-Founder & CTO at Qameta Software",*/}
      {/*    },*/}
      {/*  }}*/}
      {/*  image="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQMAAADCCAMAAAB6zFdcAAAAA1BMVEWw3+W05AztAAAASElEQVR4nO3BMQEAAADCoPVPbQwfoAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIC3AcUIAAFkqh/QAAAAAElFTkSuQmCC"*/}
      {/*/>*/}

      {/*<Feature2*/}
      {/*  title="Behaviors Header"*/}
      {/*  subtitle="During report generation (second step), the XML files are transformed to a HTML report. This can be done with a command line tool, a plugin for CI or a build tool. See examples and documentation for more details."*/}
      {/*  image="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQMAAADCCAMAAAB6zFdcAAAAA1BMVEWw3+W05AztAAAASElEQVR4nO3BMQEAAADCoPVPbQwfoAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIC3AcUIAAFkqh/QAAAAAElFTkSuQmCC"*/}
      {/*/>*/}

      <FeaturesList />
      <CTA2
        title_line1="Jste připraveni se ponořit?"
        title_line2="Začněte náš nový produkt ještě dnes!"
        text="Ac euismod vel sit maecenas id pellentesque eu sed consectetur. Malesuada adipiscing sagittis vel nulla nec."
        button_label="Spustit zkušební verzi"
      />

      <FAQ1 />

      <Footer1 />
    </div>
  </Layout>
);

export default IndexPage;
