import * as React from "react";
import img from "../assets/images/screen-launches.png";

const Hero1: React.FC = ({ title, text, image }) => (
  <div className="bg-white pb-4 sm:pb-12 lg:pb-12">
    <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-48 overflow-visible">
      <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
        <div>
          <div>
            <div className="mt-6 sm:max-w-xl">
              <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                {title}
              </h1>
              <p className="mt-6 text-xl text-gray-500">{text}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
        <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <div className="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12 ">
            <img
              className="w-full rounded-md shadow-xl lg:h-full lg:w-auto lg:max-w-none"
              src={img}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export { Hero1 };
