import React from "react";

const faqs = [
  {
    question: "Jak mohu získat bezplatnou zkušební verzi Allure TestOps?",
    answer:
      "Zašlete nám prosím žádost prostřednictvím formulářů „Získejte bezplatnou zkušební verzi“ nebo „Vyzkoušejte TestOps“ na našem webu a náš tým excelence pro zákazníky se vám brzy ozvou s vaším zkušebním klíčem.",
  },
  {
    question: "Jsme malý tým. Můžeme získat licenci pro 1–2 uživatele?",
    answer:
      "Usilovně pracujeme na tom, abychom Allure TestOps StartUp (pro malé týmy do 4) spustili letos v létě. Pokud chcete získat přístup k beta verzi TestOps StartUp, pošlete nám žádost prostřednictvím kontaktního formuláře na našem webu.",
  },
];

const FAQ1: React.FC = () => (
  <div className="bg-white" id="faq">
    <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
      <div className="lg:grid lg:grid-cols-3 lg:gap-8">
        <div>
          <h2 className="text-3xl font-extrabold text-gray-900">Často kladené otázky</h2>
          <p className="mt-4 text-lg text-gray-500">
            Nemůžete najít odpověď, kterou hledáte? Oslovte naše{" "}
            <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
              tým zákaznické podpory
            </a>{" "}
          </p>
        </div>
        <div className="mt-12 lg:mt-0 lg:col-span-2">
          <dl className="space-y-12">
            {faqs.map((faq) => (
              <div key={faq.question}>
                <dt className="text-lg leading-6 font-medium text-gray-900">{faq.question}</dt>
                <dd className="mt-2 text-base text-gray-500">{faq.answer}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  </div>
);

export { FAQ1 };
