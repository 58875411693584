import { StaticImage } from "gatsby-plugin-image";
import { InboxIcon, SparklesIcon } from "@heroicons/react/outline";
import React from "react";

const Feature2: React.FC = ({ title, subtitle, image }) => (
  <div className="mt-24">
    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
      <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
        <div>
          <div className="mt-6">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">{title}</h2>
            <p className="mt-4 text-lg text-gray-500">{subtitle}</p>
          </div>
        </div>
      </div>
      <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
        <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
          <img
            className="w-full rounded-xl shadow-xl lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
            src={image}
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
);

export { Feature2 };
