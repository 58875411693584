import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const CTA2: React.FC = ({ title_line1, title_line2, text, button_label }) => (
  <div className="bg-white">
    <div className="bg-gray-700 text-white tracking-tight mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
        <span className="block text-indigo-400">{title_line1}</span>
        <span className="block text-white">{title_line2}</span>
      </h2>
      <p className="mt-4 text-lg leading-6 text-gray-200">{text}</p>
      <div className="mt-8 flex justify-center">
        <div className="inline-flex rounded-md shadow">
          <a
            href="https://qameta.io/free-trial/"
            className="inline-flex items-center justify-center px-8 py-2 border border-transparent text-base font-medium rounded-full text-white bg-indigo-500 hover:bg-indigo-600"
          >
            {button_label}
          </a>
        </div>
      </div>
    </div>
  </div>
);

export { CTA2 };
