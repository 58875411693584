import { Quote, QuoteProps } from "app/components/quote";
import { StaticImage } from "gatsby-plugin-image";
import { InboxIcon, SparklesIcon } from "@heroicons/react/outline";
import React from "react";

interface Feature1Props {
  title: string;
  subtitle: string;
}

const Feature1: React.FC<Feature1Props> = ({ title, subtitle, quite, image }) => (
  <div className="relative">
    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
      <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
        <div>
          <div className="mt-6">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">{title}</h2>
            <p className="mt-4 text-lg text-gray-500">{subtitle}</p>
          </div>
        </div>
      </div>
      <div className="mt-12 sm:mt-16 lg:mt-0">
        <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
          <img
            className="w-full rounded-xl shadow-xl lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
            src={image}
            alt=" "
          />
        </div>
        <div className="hidden sm:block"></div>
      </div>
    </div>
  </div>
);

export { Feature1 };
