import React from "react";
import { CheckIcon } from "@heroicons/react/outline";

const features = [
  {
    name: "Bezpečnostní",
    description:
      "Zajistěte si zabezpečení na podnikové úrovni podporované přístupem na základě rolí, jednotným přihlášením, tokeny API, protokoly auditu",
  },
  {
    name: "Analytics",
    description:
      "Přehledy v reálném čase a úplná viditelnost prostřednictvím výkonného hlášení a palubní desky",
  },
  {
    name: "Agregace",
    description:
      "Agregované více testů běží do jedné zprávy pokrývající všechny komponenty, které pro vydání potřebujete",
  },
  {
    name: "Konfigurace",
    description:
      "K přístupu k vašim datům použijte bohaté REST API společně s Allure QueryLanguage",
  },
  {
    name: "Výkon",
    description:
      "Váhy vábení podle vašich potřeb. Bez problémů proveďte testy, ať už máte 1 000 testovacích případů nebo 1 000 000",
  },
  {
    name: "Integrace",
    description:
      "Nativní integrace s rámci Test Automation, CI/CD nebo Sledování problémů, které váš tým používá",
  },
];

const FeaturesList: React.FC = () => (
  <div className="bg-white" id="features">
    <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
      <div>
        <h2 className="text-base font-semibold text-indigo-600 uppercase tracking-wide">Funkce</h2>
        <p className="mt-2 text-3xl font-bold text-gray-900">Proč nás vybrat</p>
      </div>
      <div className="mt-12 lg:mt-0 lg:col-span-2">
        <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-3 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
          {features.map((feature) => (
            <div key={feature.name} className="relative">
              <dt>
                <CheckIcon className="absolute h-6 w-6 text-indigo-500" aria-hidden="true" />
                <p className="ml-9 text-lg leading-6 font-medium text-gray-900 mb-1">
                  {feature.name}
                </p>
              </dt>
              <dd className="mt-2 ml-9 text-base text-gray-500">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  </div>
);

export { FeaturesList };
